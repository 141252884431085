import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import AddEditForm from './AddEditForm'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ModalForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false
    }
  }

  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }))
  }

  render() {
      const closeBtn = <button className="close" onClick={this.toggle}>&times;</button>

      const label = this.props.buttonLabel

      let button = ''
      let title = ''

      if(label === 'Edit'){
        button = (
          <Button 
            className="btn-link p-0" 
            onClick={this.toggle}
            title="Edit Farmer"
          >
            <FontAwesomeIcon icon="edit" />
          </Button>
        );
        title = 'Edit Item'
      } else {
        button = (
          <Button 
            className="btn-link p-0" 
            onClick={this.toggle}
            title="Add Product"
          >
            <FontAwesomeIcon icon="plus-circle" />
          </Button>
        );
        title = 'Farmer Application'
      }


      return (
      <div>
        {button}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle} close={closeBtn}>{title}</ModalHeader>
          <ModalBody>
            <AddEditForm
              addItemToState={this.props.addItemToState}
              updateState={this.props.updateState}
              toggle={this.toggle}
              item={this.props.item} />
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

export default ModalForm