import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

const CustomAlert = ({ variant, heading, message, onClose, isHTML = false }) => {
  const successStyles = variant === 'success' ? {
    color: 'black'
  } : {};

  return (
    <Alert variant={variant} style={successStyles}>
      <Alert.Heading style={successStyles}>{heading}</Alert.Heading>
      {isHTML ? (
        <div 
          dangerouslySetInnerHTML={{ __html: message }} 
          style={{ ...successStyles, marginBottom: '1rem' }}
        />
      ) : (
        <div style={{ 
          ...successStyles,
          marginBottom: '1rem',
          whiteSpace: 'pre-wrap'
        }}>
          {message}
        </div>
      )}
      <hr />
      <div className="d-flex justify-content-end">
        <Button 
          onClick={onClose} 
          variant={variant === 'success' ? 'outline-dark' : `outline-${variant}`}
          className={variant === 'success' ? '' : 'text-white'}
          style={variant === 'success' ? {} : { borderColor: 'white' }}
        >
          Close me!
        </Button>
      </div>
    </Alert>
  );
};

export default CustomAlert;
