import React, { useEffect, useState } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { sendRequest } from 'services/GeneralServices';

function PrivateRoute({ component: Component, ...rest }) {
  const history = useHistory();
  const token = localStorage.getItem('token');
  const [lastValidated, setLastValidated] = useState(0);
  const VALIDATION_INTERVAL = 5 * 60 * 1000; // 5 minutes

  useEffect(() => {
    const validateToken = async () => {
      if (!token) {
        history.push('/login');
        return;
      }

      // Skip validation if checked recently
      if (Date.now() - lastValidated < VALIDATION_INTERVAL) {
        return;
      }

      try {
        await sendRequest('auth/validate-token', 'GET');
        setLastValidated(Date.now());
      } catch (error) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        history.push('/login');
      }
    };

    validateToken();
  }, [token, history, lastValidated]);

  return (
    <Route
      {...rest}
      render={props =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute; 