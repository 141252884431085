import React from 'react';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // Send error to your error tracking service
    if (process.env.NODE_ENV === 'production') {
      // Example: If using a service like Sentry
      // Sentry.captureException(error, { extra: errorInfo });
      
      // Or send to your logging API
      this.logError(error, errorInfo);
    } else {
      // Keep console.error in development only
      console.error('Error caught by boundary:', error, errorInfo);
    }
  }

  logError(error, errorInfo) {
    // Implementation depends on your error logging service
    // Example:
    // fetch('/api/log-error', {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({ error, errorInfo })
    // });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorMessage
          title="Something went wrong"
          message={this.state.error?.message || "An unexpected error occurred"}
          onClose={() => this.setState({ hasError: false, error: null })}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary; 