import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";

import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";

import AdminLayout from "layouts/Admin.js";
import Login from "views/Login";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrashCan,faCirclePlus,faEye,faTrash,faSort,faSortUp,faSortDown,faSearch} from '@fortawesome/free-solid-svg-icons';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

library.add(faTrashCan,faCirclePlus,faEye,faTrash,faSort,faSortUp,faSortDown,faSearch);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 10 * 60 * 1000, // 10 minutes
    },
  },
});

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem("token");
  const isLoginPage = rest.path === "/login";
  
  // If we're on login page and there's no token, just render the login component
  if (isLoginPage && !token) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
  
  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { 
                from: props.location,
                intended: props.location.pathname 
              }
            }}
          />
        )
      }
    />
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <Switch>
        <PrivateRoute path="/admin" component={AdminLayout} />
        <PrivateRoute path="/login" component={Login} />
        <Redirect from="/" to="/admin/dashboard" />
      </Switch>
    </BrowserRouter>
    {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
  </QueryClientProvider>
);
