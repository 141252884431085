import React, { useState } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import { sendRequest } from "services/GeneralServices";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import EnhancedTable from "components/DataTable/EnhancedTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Buyers() {
  const [data, setData] = useState({ items: [], total: 0 });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    total: 0,
    page: 1,
    pages: 1,
    limit: 10
  });

  const columns = [
    {
      key: 'email',
      label: 'Email',
      sortable: true,
      render: (item) => item?.user?.email || '-'
    },
    {
      key: 'type',
      label: 'Type',
      sortable: true,
      render: (item) => item.type ? item.type.charAt(0).toUpperCase() + item.type.slice(1) : '-'
    },
    {
      key: 'businessName',
      label: 'Business Name',
      sortable: true,
      render: (item) => item.businessName || '-'
    },
    {
      key: 'businessType',
      label: 'Business Type',
      sortable: true,
      render: (item) => item.businessType || '-'
    },
    {
      key: 'addresses',
      label: 'Location',
      render: (item) => {
        const primaryAddress = item.addresses?.[0];
        if (!primaryAddress) return '-';
        return [
          primaryAddress.city,
          primaryAddress.country
        ].filter(Boolean).join(', ') || '-';
      }
    }
  ];

  const fetchBuyers = async (queryParams) => {
    setIsLoading(true);
    setError("");
    try {
      const url = `buyers?page=${queryParams.page}&limit=${queryParams.limit}&sort=${queryParams.sort}${queryParams.search ? `&search=${queryParams.search}` : ''}`;
      const response = await sendRequest(url, "GET");
      
      if (response?.success) {
        setData({
          items: response.data,
          total: response.pagination.total
        });
        setPagination(response.pagination);
      } else {
        throw new Error(response?.msg || "Failed to fetch buyers");
      }
    } catch (error) {
      setError(error.message || "An error occurred while fetching buyers");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this buyer?')) {
      try {
        await sendRequest(`users/${id}`, "DELETE");
        fetchBuyers({ page: 1, limit: 10, sort: '-createdAt' });
      } catch (error) {
        setError(error.message || "Failed to delete buyer");
      }
    }
  };

  const renderActions = (item) => (
    <>
      <FontAwesomeIcon
        icon="eye"
        className="action-icon text-info"
        onClick={() => window.open(`/buyer/${item._id}`, '_blank')}
        title="View Buyer Details"
      />
      <FontAwesomeIcon
        icon="trash-can"
        className="action-icon text-danger"
        onClick={() => handleDelete(item?.user?._id)}
        title="Delete Buyer"
      />
    </>
  );

  return (
    <ErrorBoundary>
      <Container fluid>
        {error && (
          <Row>
            <Col xs={12}>
              <ErrorMessage
                title="Error Loading Buyers"
                message={error}
                onClose={() => setError("")}
              />
            </Col>
          </Row>
        )}
        
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Buyers Management</Card.Title>
                <p className="card-category">List of all registered buyers</p>
              </Card.Header>
              <Card.Body>
                <EnhancedTable
                  columns={columns}
                  data={data.items}
                  totalItems={data.total}
                  isLoading={isLoading}
                  onFetchData={fetchBuyers}
                  actions={renderActions}
                  searchPlaceholder="Search buyers..."
                  pagination={pagination}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </ErrorBoundary>
  );
}

export default Buyers; 