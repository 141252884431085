import React, { useState } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import { sendRequest } from "services/GeneralServices";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import EnhancedTable from "components/DataTable/EnhancedTable";
import ModalForm from "Forms/FarmerPending/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function PendingFarmers() {
  const [data, setData] = useState({ items: [], total: 0 });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    total: 0,
    page: 1,
    pages: 1,
    limit: 10
  });

  const columns = [
    {
      key: 'firstname',
      label: 'First Name',
      sortable: true,
      render: (item) => item?.user?.firstname || '-'
    },
    {
      key: 'lastname',
      label: 'Last Name',
      sortable: true,
      render: (item) => item?.user?.lastname || '-'
    },
    {
      key: 'email',
      label: 'Email',
      sortable: true,
      render: (item) => item?.user?.email || '-'
    },
    {
      key: 'businessName',
      label: 'Business Name',
      sortable: true
    },
    {
      key: 'businessType',
      label: 'Business Type',
      sortable: true
    },
    {
      key: 'createdAt',
      label: 'Registration Date',
      sortable: true,
      render: (item) => new Date(item.createdAt).toLocaleDateString()
    }
  ];

  const fetchFarmers = async (queryParams) => {
    setIsLoading(true);
    setError("");
    try {
      const url = `users/farmers?status=false&page=${queryParams.page}&limit=${queryParams.limit}&sort=${queryParams.sort}${queryParams.search ? `&search=${queryParams.search}` : ''}`;
      const response = await sendRequest(url, "GET");
      
      if (response?.success) {
        setData({
          items: response.data,
          total: response.pagination.total
        });
        setPagination(response.pagination);
      } else {
        throw new Error(response?.msg || "Failed to fetch pending farmers");
      }
    } catch (error) {
      setError(error.message || "An error occurred while fetching pending farmers");
    } finally {
      setIsLoading(false);
    }
  };

  const handleApprove = async (id) => {
    try {
      await sendRequest(`verify-user/${id}`, "PATCH");
      fetchFarmers({ page: 1, limit: 10, sort: '-createdAt' });
    } catch (error) {
      setError(error.message || "Failed to approve farmer");
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this farmer?')) {
      try {
        await sendRequest(`users/${id}`, "DELETE");
        fetchFarmers({ page: 1, limit: 10, sort: '-createdAt' });
      } catch (error) {
        setError(error.message || "Failed to delete farmer");
      }
    }
  };

  const renderActions = (item) => (
    <>
      <div className="action-icon">
        <ModalForm
          buttonLabel="Edit"
          item={item}
          updateState={() => fetchFarmers({ page: 1, limit: 10, sort: '-createdAt' })}
        />
      </div>
      <FontAwesomeIcon
        icon="check-circle"
        className="action-icon text-success"
        onClick={() => handleApprove(item?.user?._id)}
        title="Approve Farmer"
      />
      <FontAwesomeIcon
        icon="trash-can"
        className="action-icon text-danger"
        onClick={() => handleDelete(item?.user?._id)}
        title="Delete Farmer"
      />
    </>
  );

  return (
    <ErrorBoundary>
      <Container fluid>
        {error && (
          <Row>
            <Col xs={12}>
              <ErrorMessage
                title="Error Loading Pending Farmers"
                message={error}
                onClose={() => setError("")}
              />
            </Col>
          </Row>
        )}
        
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Pending Farmer's Account Management</Card.Title>
                <p className="card-category">List of all Farmers awaiting approval</p>
              </Card.Header>
              <Card.Body>
                <EnhancedTable
                  columns={columns}
                  data={data.items}
                  totalItems={data.total}
                  isLoading={isLoading}
                  onFetchData={fetchFarmers}
                  actions={renderActions}
                  searchPlaceholder="Search pending farmers..."
                  pagination={pagination}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </ErrorBoundary>
  );
}

export default PendingFarmers;
