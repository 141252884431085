import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Button, Badge } from 'react-bootstrap';
import { BsBell } from 'react-icons/bs';
import { sendRequest } from "services/GeneralServices";
import './NotificationIcon.css';

function NotificationIcon() {
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const history = useHistory();

  useEffect(() => {
    fetchRecentNotifications();
    const interval = setInterval(fetchRecentNotifications, 30000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.notification-container')) {
        setShowNotifications(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const fetchRecentNotifications = async () => {
    try {
      const response = await sendRequest('notifications/recent', 'GET');
      if (response?.success) {
        setNotifications(response.data.slice(0, 5));
        setUnreadCount(response.unreadCount || 0);
      }
    } catch (error) {
      setNotifications([]);
      setUnreadCount(0);
    }
  };

  const handleMarkAsRead = async (id) => {
    try {
      await sendRequest(`notifications/${id}/read`, 'PATCH');
      fetchRecentNotifications();
    } catch (error) {
      // Handle error silently or show toast
    }
  };

  const handleViewAll = () => {
    setShowNotifications(false);
    history.push('/admin/notifications');
  };

  return (
    <div className="notification-container">
      <div 
        className="notification-icon" 
        onClick={(e) => {
          e.stopPropagation();
          setShowNotifications(!showNotifications);
        }}
      >
        <BsBell size={20} />
        {unreadCount > 0 && (
          <Badge bg="danger" className="notification-badge">
            {unreadCount}
          </Badge>
        )}
      </div>

      {showNotifications && (
        <Card className="notification-card">
          <Card.Header>
            <h6 className="mb-0">Recent Notifications</h6>
          </Card.Header>
          <Card.Body className="notification-list">
            {notifications.length === 0 ? (
              <p className="text-muted text-center">No new notifications</p>
            ) : (
              notifications.map(notification => (
                <div 
                  key={notification._id} 
                  className={`notification-item ${!notification.read ? 'unread' : ''}`}
                  onClick={() => handleMarkAsRead(notification._id)}
                >
                  <div className="notification-content">
                    <p>{notification.message}</p>
                    <small className="text-muted">
                      {new Date(notification.createdAt).toLocaleString()}
                    </small>
                  </div>
                  {!notification.read && (
                    <Badge bg="primary" className="unread-badge">New</Badge>
                  )}
                </div>
              ))
            )}
          </Card.Body>
          <Card.Footer>
            <Button 
              variant="primary" 
              size="sm" 
              className="w-100"
              onClick={handleViewAll}
            >
              View All Notifications
            </Button>
          </Card.Footer>
        </Card>
      )}
    </div>
  );
}

export default NotificationIcon; 