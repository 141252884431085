const API_URL = process.env.REACT_APP_API_URL;

class ValidationError extends Error {
  constructor(errors) {
    super('Validation failed');
    this.name = 'ValidationError';
    this.errors = errors;
  }
}

export const sendRequest = async (endpoint, method = 'GET', data = null, skipAuth = false, skipRedirect = false) => {
  try {
    if (!API_URL) {
      throw new Error('Error connecting to service: API URL is not configured');
    }

    const token = localStorage.getItem('token');
    
    const headers = {
      'Content-Type': 'application/json',
      ...(token && !skipAuth && { Authorization: `Bearer ${token}` })
    };

    const cleanEndpoint = endpoint.startsWith('/') ? endpoint.slice(1) : endpoint;
    const cleanApiUrl = API_URL?.endsWith('/') ? API_URL?.slice(0, -1) : API_URL;
    const url = `${cleanApiUrl}/api/v1/${cleanEndpoint}`;

    const options = {
      method,
      headers,
      ...(data && { body: JSON.stringify(data) })
    };

    try {
      const response = await fetch(url, options);
      
      // Handle non-JSON responses
      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        throw new Error('Invalid response format from server');
      }

      const responseData = await response.json();
     

      if (!response.ok) {
        if(responseData?.success === false && responseData?.msg?.includes("Invalid credentials")) {
          throw new Error("Invalid credentials");
        }
        if (response.status === 401 && !skipRedirect) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          //window.location.href = '/login';
          throw new Error('Session expired. Please login again.');
        }
        if (response.status === 400 && responseData?.msg?.includes("Validation failed") && responseData?.errors && responseData?.errors?.length > 0) {
          throw new ValidationError(responseData.errors);
        }

        // Handle specific error status codes
        switch (response.status) {
          case 400:
            throw new Error(responseData.msg || responseData.error || `Request failed with status ${response.status}`);
          case 401:
            throw new Error("Invalid credentials");
          case 403:
            throw new Error('You do not have permission to perform this action');
          case 404:
            throw new Error('The requested resource was not found');
          case 429:
            throw new Error('Too many requests. Please try again later');
          case 500:
            throw new Error('Internal server error. Please try again later');
          default:
            throw new Error(responseData.message || responseData.error || `Request failed with status ${response.status}`);
        }
      }

      return responseData;
    } catch (error) {
      if (error.name === 'TypeError' && error.message.includes('Failed to fetch')) {
        throw new Error('Error connecting to service: Server may be down or unreachable');
      }
      throw error;
    }
  } catch (error) {
    // Handle specific error types
    if (error.name === 'ValidationError' || 
        error.message === 'Session expired. Please login again.' ||
        error.message.includes('Error connecting to service')) {
      throw error; // Throw the original error object
    }

    // Log error to monitoring service in production
    if (process.env.NODE_ENV === 'production') {
      // Here you would typically log to a service like Sentry, LogRocket, etc.
      // logErrorToService(error, { url, method, endpoint });
    }

    throw new Error(error.message || 'An error occurred while making the request');
  }
};
