import Dashboard from "views/Dashboard.js";
import Orders from "views/Orders.js";
import Products from "views/Products.js";
import ApprovedBuyer from "views/ApprovedBuyer.js";
import ApprovedFarmers from "views/ApprovedFarmers";
import PendingFarmers from "views/PendingFarmers.js";
import PendingBuyer from "views/PendingBuyer.js";
import Upgrade from "views/Upgrade.js";
import RegisterUser from "views/RegisterUsers";
import Buyers from "views/Buyers.js";
import Notifications from "views/Notifications.js";
import Users from "views/Users";
// import UserDetails from "views/UserDetails";
const dashboardRoutes = [
  {
    upgrade: true,
    path: "/upgrade",
    name: "efama @2023",
    icon: "nc-icon nc-alien-33",
    component: Upgrade,
    layout: "/admin",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/orders",
    name: "Orders",
    icon: "nc-icon nc-delivery-fast",
    component: Orders,
    layout: "/admin",
  },
  {
    path: "/products",
    name: "Products",
    icon: "nc-icon nc-apple",
    component: Products,
    layout: "/admin",
  },
  {
    path: "/icons",
    name: "Farmers (Approved)",
    icon: "nc-icon nc-circle-09",
    component: ApprovedFarmers,
    layout: "/admin",
  },
  {
    path: "/maps",
    name: "Farmers (Pending)",
    icon: "nc-icon nc-circle-09",
    component: PendingFarmers,
    layout: "/admin",
  },
  {
    path: "/buyers",
    name: "Buyers",
    icon: "nc-icon nc-single-02",
    component: Buyers,
    layout: "/admin"
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: "nc-icon nc-bell-55",
    component: Notifications,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    icon: "nc-icon nc-circle-09",
    component: Users,
    layout: "/admin"
  },
  // {
  //   path: "/users/:id",
  //   name: "User Details",
  //   component: UserDetails,
  //   layout: "/admin",
  //   redirect: true
  // }
];

export default dashboardRoutes;
