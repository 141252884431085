import React from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { sendRequest } from "services/GeneralServices";
import Spinner from "react-bootstrap/Spinner";


class AddEditForm extends React.Component {
  state = {
    _id: 0,
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    phone: "",
    type: "",
    username: "",
    isVerified: false,
    businessName: "",
    businessType: "",
    companySize: "",
    funding: null,
    hasPackHouse: false,
    isLookingForFunding: false,
    land: null,
    registrationNumber: "",
    revenueSize: "",
    userId: "",
    ownership: "",
    totalPloughed: 0,
    size: 0,
    bank: "",
    hasCropInsurance: false,
    source: "",
    isLoading: false,
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitFormAdd = (e) => {
    e.preventDefault();
    this.props.addItemToState({
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      email: this.state.email,
      phone: this.state.phone,
      isVerified: this.state.isVerified,
      type: this.state.type,
    });
    this.props.toggle();
  };

  submitFormEdit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    this.props.updateState({
      _id: this.state._id,
      user: {
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        username: this.state.username,
        email: this.state.email,
        phone: this.state.phone,
        isVerified: this.state.isVerified,
        _id: this.state.userId,
      },
      type: this.state.type,
      businessName: this.state.businessName,
      businessType: this.state.businessType,
      companySize: this.state.companySize,
      funding: this.state.funding,
      hasPackHouse: this.state.hasPackHouse,
      isLookingForFunding: this.state.isLookingForFunding,
      land: this.state.land,
      registrationNumber: this.state.registrationNumber,
      revenueSize: this.state.revenueSize,
    });
    const user = await sendRequest(
      `/users/${this.state.userId}`,
      "PATCH",
      {
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        location: "",
        province: "",
        phone: this.state.phone,
        email: this.state.email,
      },
      true
    );
    if (user) {
      if (user.status === 200 || user.status === 201) {
        // Success case - no need for console.log
      } else {
        window.alert(user?.msg || "Failed to update user information");
      }
    } else {
      window.alert("Failed to update user information. Please try again.");
    }

    const kyc = await sendRequest(
      `/kyc/farmer/${this.state.userId}`,
      "PATCH",
      {
        land: {
          size: this.state?.size,
          totalPloughed: this.state?.totalPloughed,
          ownership: this.state?.ownership,
        },
        funding: {
          source: this.state?.source,
          hasCropInsurance: this.state?.hasCropInsurance,
          bank: this.state?.bank,
        },
        certifications: [
          {
            name: "",
            description: "",
          },
        ],
        farmType: this.state.type,
        businessName: this.state.businessName,
        registrationNumber: this.state.registrationNumber,
        hasPackHouse: this.state.hasPackHouse,
        isLookingForFunding: this.state.isLookingForFunding,
        businessType: this.state.businessType,
        companySize: this.state.companySize,
        revenueSize: this.state.revenueSize,
      },
      true
    );
    if (kyc) {
      if (kyc.status === 200 || kyc.status === 201) {
        // Success case - no need for console.log
      } else {
        window.alert(kyc?.msg || "Failed to update KYC information");
      }
    } else {
      window.alert("Failed to update KYC information. Please try again.");
    }
    this.setState({ isLoading: false });
    this.props.toggle();
  };
  onApproveUser = async (e) => {
    this.setState({ [e.target.name]: e.target.value });
    const res = await sendRequest(
      `verify-user/${this.state.userId}`,
      "PATCH",
      null,
      true
    );
    if (res) {
      if (res.status === 200 || res.status === 201) {
        //this.props.deleteItemFromState(id);
      } else {
        window.alert(res?.msg || "Failed to update approval status");
      }
    } else {
      window.alert("Something went wrong while approving the user. Please try again.");
    }
  };

  componentDidMount() {
    // if item exists, populate the state with proper data
    if (this.props.item) {
      const {
        _id,
        user,
        type,
        businessName,
        businessType,
        companySize,
        funding,
        hasPackHouse,
        isLookingForFunding,
        land,
        registrationNumber,
        revenueSize,
        certifications,
      } = this.props.item;
      this.setState({
        _id,
        firstname: user?.firstname,
        lastname: user?.lastname,
        username: user?.username,
        email: user?.email,
        phone: user?.phone,
        userId: user?._id,
        isVerified: user?.isVerified,
        type: type,
        businessName,
        businessType,
        companySize,
        funding,
        bank: funding?.bank,
        hasCropInsurance: funding?.hasCropInsurance,
        source: funding?.source,
        hasPackHouse,
        isLookingForFunding,
        land,
        ownership: land?.ownership,
        totalPloughed: land?.totalPloughed,
        size: land?.size,
        registrationNumber,
        revenueSize,
        certifications,
      });
    }
  }
  render() {
    return (
      <>
        {this.state.isLoading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Submitting...</span>
            </Spinner>
            <p>This might take few seconds</p>
          </div>
        ) : (
          <Form
            onSubmit={
              this.props.item ? this.submitFormEdit : this.submitFormAdd
            }
          >
            <FormGroup>
              <Label for="first">First Name</Label>
              <Input
                disabled
                type="text"
                name="firstname"
                id="firstname"
                onChange={this.onChange}
                value={
                  this.state.firstname === null ? "" : this.state.firstname
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="lastname">Last Name</Label>
              <Input
                disabled
                type="text"
                name="lastname"
                id="lastname"
                onChange={this.onChange}
                value={this.state.lastname === null ? "" : this.state.lastname}
              />
            </FormGroup>
            <FormGroup>
              <Label for="lastname">User Name</Label>
              <Input
                disabled
                type="text"
                name="username"
                id="username"
                onChange={this.onChange}
                value={this.state.username === null ? "" : this.state.username}
              />
            </FormGroup>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                disabled
                type="email"
                name="email"
                id="email"
                onChange={this.onChange}
                value={this.state.email === null ? "" : this.state.email}
              />
            </FormGroup>
            <FormGroup>
              <Label for="phone">Phone</Label>
              <Input
                disabled
                type="text"
                name="phone"
                id="phone"
                onChange={this.onChange}
                value={this.state.phone === null ? "" : this.state.phone}
                placeholder="ex. 555-555-5555"
              />
            </FormGroup>

            <h6 class="text-uppercase">Farmer Profile</h6>
            <hr class="solid" />
            <FormGroup>
              <Label for="hobby">Business Name</Label>
              <Input
                type="text"
                name="businessName"
                id="businessName"
                onChange={this.onChange}
                value={this.state.businessName}
              />
            </FormGroup>
            <div>
              <label>
                Business Type
                <select
                  name="businessType"
                  style={{
                    width: "100px",
                    padding: 10,
                    borderRadius: 10,
                    width: "450px",
                    margin: "10px 0px",
                  }}
                  value={this.state.businessType}
                  onChange={this.onChange}
                >
                  <option value="">-- Select --</option>
                  <option value="Sole proprietor">Sole Proprietor</option>
                  <option value="Pty">Pty</option>
                  <option value="Co-Op">Co-Op</option>
                  <option value="CC">CC</option>
                </select>
              </label>
            </div>
            <FormGroup>
              <Label for="hobby">Company Size</Label>
              <Input
                type="text"
                name="companySize"
                id="companySize"
                onChange={this.onChange}
                value={this.state.companySize}
              />
            </FormGroup>
            <div>
              <label>
                Has PackHouse
                <select
                  name="hasPackHouse"
                  style={{
                    width: "100px",
                    padding: 10,
                    borderRadius: 10,
                    width: "450px",
                    margin: "10px 0px",
                  }}
                  value={
                    this.state.hasPackHouse ? this.state.hasPackHouse : false
                  }
                  onChange={this.onChange}
                >
                  <option value="">-- Select --</option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
              </label>
            </div>
            <div>
              <label>
                Is Looking for Funding
                <select
                  name="isLookingForFunding"
                  style={{
                    width: "100px",
                    padding: 10,
                    borderRadius: 10,
                    width: "450px",
                    margin: "10px 0px",
                  }}
                  value={
                    this.state.isLookingForFunding
                      ? this.state.isLookingForFunding
                      : false
                  }
                  onChange={this.onChange}
                >
                  <option value="">-- Select --</option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
              </label>
            </div>
            <FormGroup>
              <Label for="hobby">Registration Number</Label>
              <Input
                type="text"
                name="registrationNumber"
                id="registrationNumber"
                onChange={this.onChange}
                value={this.state.registrationNumber}
              />
            </FormGroup>
            <FormGroup>
              <Label for="hobby">Revenue Size</Label>
              <Input
                type="text"
                name="revenueSize"
                id="revenueSize"
                onChange={this.onChange}
                value={this.state.revenueSize}
              />
            </FormGroup>
            <h6 class="text-uppercase">Land Details</h6>
            <hr class="solid" />
            <FormGroup>
              <Label for="hobby">Ownership</Label>
              <Input
                type="text"
                name="ownership"
                id="ownership"
                onChange={this.onChange}
                value={this.state?.ownership}
              />
            </FormGroup>
            <FormGroup>
              <Label for="hobby">Land Size</Label>
              <Input
                type="text"
                name="size"
                id="size"
                onChange={this.onChange}
                value={this.state?.size}
              />
            </FormGroup>
            <FormGroup>
              <Label for="hobby">Total Ploughed</Label>
              <Input
                type="text"
                name="totalPloughed"
                id="totalPloughed"
                onChange={this.onChange}
                value={this.state?.totalPloughed}
              />
            </FormGroup>
            <h6 class="text-uppercase">Funding Details</h6>
            <hr class="solid" />
            <div>
              <label>
                Select Bank
                <select
                  name="bank"
                  style={{
                    width: "100px",
                    padding: 10,
                    borderRadius: 10,
                    width: "450px",
                    margin: "10px 0px",
                  }}
                  value={this.state.bank}
                  onChange={this.onChange}
                >
                  <option value="">-- Select --</option>
                  <option value="Absa Bank">Absa Bank</option>
                  <option value="African Bank">African Bank</option>
                  <option value="Capitec Bank">Capitec</option>
                  <option value="First National Bank">
                    First National Bank
                  </option>
                  <option value="First Rand Bank">First Rand Bank</option>
                  <option value="Nedbank">Nedbank</option>
                  <option value="Merchantile Bank">Merchantile Bank</option>
                  <option value="Post Bank">Post Bank</option>
                  <option value="Sasfin Bank Limited">
                    Sasfin Bank Limited
                  </option>
                  <option value="Tyme Bank">Tyme Bank</option>
                </select>
              </label>
            </div>
            <div>
              <label>
                Has Crop Insurance ?
                <select
                  name="hasCropInsurance"
                  style={{
                    width: "100px",
                    padding: 10,
                    borderRadius: 10,
                    width: "450px",
                    margin: "10px 0px",
                  }}
                  value={
                    this.state.hasCropInsurance
                      ? this.state.hasCropInsurance
                      : false
                  }
                  onChange={this.onChange}
                >
                  <option value="">-- Select --</option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </select>
              </label>
            </div>
            <div>
              <label>
                Source of Funding
                <select
                  name="source"
                  style={{
                    width: "100px",
                    padding: 10,
                    borderRadius: 10,
                    width: "450px",
                    margin: "10px 0px",
                  }}
                  value={this.state.source}
                  onChange={this.onChange}
                >
                  <option value="">-- Select --</option>
                  <option value="self-funding">self-funding</option>
                  <option value="Government">Government</option>
                  <option value="Bank">Bank</option>
                  <option value="Development Fund">Development Fund</option>
                  <option value="Grant">Grant</option>
                  <option value="Other">Other</option>
                </select>
              </label>
            </div>
            <h6 class="text-uppercase">Approval Section</h6>
            <hr class="solid" />
            <FormGroup>
              <Label for="location">Is the Farmer Account Approved ? </Label>
              <Input
                disabled
                type="text"
                name="isVerified"
                id="isVerified"
                onChange={this.onChange}
                value={this.state.isVerified ? this.state.isVerified : false}
              />
            </FormGroup>
            <div>
              <label>
                Change Approval status:
                <select
                  name="isVerified"
                  style={{
                    width: "100px",
                    padding: 10,
                    borderRadius: 10,
                    width: "450px",
                    margin: "10px 0px",
                  }}
                  value={this.state.isVerified ? this.state.isVerified : false}
                  onChange={this.onApproveUser}
                >
                  <option value="">-- Select --</option>
                  <option value={true}>Approve</option>
                  <option value={false}>Not Approved</option>
                </select>
              </label>
              {/* <h6 class="text-uppercase">Uploaded Documents</h6>
        <hr class="solid" />
        <ul>
        {this.state.certifications?.map((certification, index) => (
          <li key={index}>
            <a href='#'  >{certification?.name} {index}</a>
          </li>
        ))}
      </ul> */}
            </div>
            <Button>Submit</Button>
          </Form>
        )}
      </>
    );
  }
}

export default AddEditForm;
