import React, { useState } from "react";
import { Card, Container, Row, Col, Form } from "react-bootstrap";
import { sendRequest } from "services/GeneralServices";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import EnhancedTable from "components/DataTable/EnhancedTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Notifications() {
  const [data, setData] = useState({ items: [], total: 0 });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    total: 0,
    page: 1,
    pages: 1,
    limit: 10
  });

  const columns = [
    {
      key: 'type',
      label: 'Type',
      sortable: true,
      render: (item) => (
        <span className={`badge bg-${getTypeColor(item.type)}`}>
          {item.type || 'System'}
        </span>
      )
    },
    {
      key: 'message',
      label: 'Message',
      sortable: true
    },
    {
      key: 'createdAt',
      label: 'Date',
      sortable: true,
      render: (item) => new Date(item.createdAt).toLocaleString()
    },
    {
      key: 'status',
      label: 'Status',
      sortable: true,
      render: (item) => (
        <span className={`badge bg-${item.read ? 'secondary' : 'primary'}`}>
          {item.read ? 'Read' : 'Unread'}
        </span>
      )
    }
  ];

  const getTypeColor = (type) => {
    switch (type?.toLowerCase()) {
      case 'system':
        return 'info';
      case 'alert':
        return 'warning';
      case 'message':
        return 'success';
      default:
        return 'secondary';
    }
  };

  const fetchNotifications = async (queryParams) => {
    setIsLoading(true);
    setError("");
    try {
      const url = `notifications?page=${queryParams.page}&limit=${queryParams.limit}&sort=${queryParams.sort}${queryParams.search ? `&search=${queryParams.search}` : ''}`;
      const response = await sendRequest(url, "GET");
      
      if (response?.success) {
        setData({
          items: response.data,
          total: response.pagination.total
        });
        setPagination(response.pagination);
      } else {
        throw new Error(response?.msg || "Failed to fetch notifications");
      }
    } catch (error) {
      setError(error.message || "An error occurred while fetching notifications");
    } finally {
      setIsLoading(false);
    }
  };

  const handleMarkAsRead = async (id) => {
    try {
      await sendRequest(`notifications/${id}/read`, "PATCH");
      fetchNotifications({ page: 1, limit: 10, sort: '-createdAt' });
    } catch (error) {
      setError(error.message || "Failed to mark notification as read");
    }
  };

  const renderActions = (item) => (
    <>
      {!item.read && (
        <FontAwesomeIcon
          icon="check"
          className="action-icon text-success"
          onClick={() => handleMarkAsRead(item._id)}
          title="Mark as Read"
        />
      )}
    </>
  );

  return (
    <ErrorBoundary>
      <Container fluid>
        {error && (
          <Row>
            <Col xs={12}>
              <ErrorMessage
                title="Error Loading Notifications"
                message={error}
                onClose={() => setError("")}
              />
            </Col>
          </Row>
        )}
        
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Notifications</Card.Title>
                <p className="card-category">All system notifications and alerts</p>
              </Card.Header>
              <Card.Body>
                <EnhancedTable
                  columns={columns}
                  data={data.items}
                  totalItems={data.total}
                  isLoading={isLoading}
                  onFetchData={fetchNotifications}
                  actions={renderActions}
                  searchPlaceholder="Search notifications..."
                  pagination={pagination}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </ErrorBoundary>
  );
}

export default Notifications; 