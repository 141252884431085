import React, { useState, useEffect } from "react";
import ChartistGraph from "react-chartist";
import Spinner from 'react-bootstrap/Spinner';
import {
  Card,
  Container,
  Row,
  Col,
  Table,
  Form,
  InputGroup,
  Dropdown,
  ButtonGroup
} from "react-bootstrap";
import { sendRequest } from "services/GeneralServices";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import dashboardData from "../data/dashboardData.json";
import './Dashboard.css';

function Dashboard() {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({
    dateRange: 'all', // all, today, week, month, year
    orderStatus: 'all', // all, pending, accepted, delivered, rejected
    productCategory: 'all' // all, fruits, vegetables, etc.
  });

  useEffect(() => {
    fetchDashboardData();
  }, [filters]); // Refetch when filters change

  const fetchDashboardData = async () => {
    setIsLoading(true);
    setError("");
    
    try {
      // Simulate API call delay with filters
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // In real implementation, you would send filters to the API
      // const url = `dashboard?dateRange=${filters.dateRange}&status=${filters.orderStatus}&category=${filters.productCategory}`;
      // const response = await sendRequest(url, "GET");
      
      // For now, we'll filter the dummy data
      const filteredData = filterDashboardData(dashboardData.data);
      setData(filteredData);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const filterDashboardData = (rawData) => {
    // Clone the data to avoid mutating the original
    let filteredData = JSON.parse(JSON.stringify(rawData));
    
    // Apply date range filter
    if (filters.dateRange !== 'all') {
      const now = new Date();
      const startDate = getStartDate(filters.dateRange);
      
      // Filter orders
      filteredData.stats.orders.recentOrders = filteredData.stats.orders.recentOrders.filter(
        order => new Date(order.datePurchased) >= startDate
      );
      
      // Update totals and stats accordingly
      // ... (implement other date-based filtering)
    }

    // Apply order status filter
    if (filters.orderStatus !== 'all') {
      filteredData.stats.orders.recentOrders = filteredData.stats.orders.recentOrders.filter(
        order => order.status.toLowerCase() === filters.orderStatus.toLowerCase()
      );
    }

    // Apply product category filter
    if (filters.productCategory !== 'all') {
      // Filter product-related stats
      // ... (implement category filtering)
    }

    return filteredData;
  };

  const getStartDate = (range) => {
    const now = new Date();
    switch (range) {
      case 'today':
        return new Date(now.setHours(0, 0, 0, 0));
      case 'week':
        return new Date(now.setDate(now.getDate() - 7));
      case 'month':
        return new Date(now.setMonth(now.getMonth() - 1));
      case 'year':
        return new Date(now.setFullYear(now.getFullYear() - 1));
      default:
        return new Date(0); // beginning of time
    }
  };

  const renderFilterControls = () => (
    <div className="dashboard-filters">
      <Row className="dashboard-filters-row">
        <Col md={4}>
          <Form.Group>
            <Form.Label>
              <i className="fas fa-calendar-alt me-2"></i>
              Date Range
            </Form.Label>
            <Form.Select
              value={filters.dateRange}
              onChange={(e) => setFilters(prev => ({ ...prev, dateRange: e.target.value }))}
            >
              <option value="all">All Time</option>
              <option value="today">Today</option>
              <option value="week">Last 7 Days</option>
              <option value="month">Last 30 Days</option>
              <option value="year">This Year</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group>
            <Form.Label>
              <i className="fas fa-tasks me-2"></i>
              Order Status
            </Form.Label>
            <Form.Select
              value={filters.orderStatus}
              onChange={(e) => setFilters(prev => ({ ...prev, orderStatus: e.target.value }))}
            >
              <option value="all">All Status</option>
              <option value="pending">Pending</option>
              <option value="accepted">Accepted</option>
              <option value="delivered">Delivered</option>
              <option value="rejected">Rejected</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group>
            <Form.Label>
              <i className="fas fa-tag me-2"></i>
              Product Category
            </Form.Label>
            <Form.Select
              value={filters.productCategory}
              onChange={(e) => setFilters(prev => ({ ...prev, productCategory: e.target.value }))}
            >
              <option value="all">All Categories</option>
              <option value="fruits">Fruits</option>
              <option value="vegetables">Vegetables</option>
              <option value="livestock">Livestock</option>
              <option value="meat">Meat</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
    </div>
  );

  const renderStatsCard = (title, value, icon, color, subtitle) => (
    <Card className="card-stats">
      <Card.Body>
        <Row>
          <Col xs="5">
            <div className="icon-big text-center icon-warning">
              <i className={`nc-icon ${icon} text-${color}`} />
            </div>
          </Col>
          <Col xs="7">
            <div className="numbers">
              <p className="card-category">{title}</p>
              <Card.Title as="h4">
                {isLoading ? <Spinner animation="border" size="sm" /> : value}
              </Card.Title>
            </div>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer>
        <hr />
        <div className="stats">
          <i className="fas fa-redo mr-1" />
          {subtitle}
        </div>
      </Card.Footer>
    </Card>
  );

  if (isLoading) {
    return <div className="text-center p-5"><Spinner animation="border" /></div>;
  }

  const stats = data?.stats || {};

  return (
    <ErrorBoundary>
      <Container fluid>
        {error && (
          <Row>
            <Col xs={12}>
              <ErrorMessage
                title="Dashboard Error"
                message={error}
                onClose={() => setError("")}
              />
            </Col>
          </Row>
        )}

        {renderFilterControls()}

        <Row>
          <Col lg="3" sm="6">
            {renderStatsCard(
              "Total Users",
              stats.users?.total || 0,
              "nc-circle-09",
              "warning",
              "All registered users"
            )}
          </Col>
          <Col lg="3" sm="6">
            {renderStatsCard(
              "Approved Farmers",
              stats.users?.farmers?.approved || 0,
              "nc-check-2",
              "success",
              "Active farmers"
            )}
          </Col>
          <Col lg="3" sm="6">
            {renderStatsCard(
              "Total Orders",
              stats.orders?.total || 0,
              "nc-cart-simple",
              "info",
              "All time orders"
            )}
          </Col>
          <Col lg="3" sm="6">
            {renderStatsCard(
              "Revenue",
              `R ${(stats.revenue?.total || 0).toFixed(2)}`,
              "nc-money-coins",
              "danger",
              "Total earnings"
            )}
          </Col>
        </Row>

        <Row>
          <Col md="8">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Weekly Orders Overview</Card.Title>
                <p className="card-category">Last 7 Days Performance</p>
              </Card.Header>
              <Card.Body>
                <div className="ct-chart" id="chartActivity">
                  <ChartistGraph
                    data={{
                      labels: stats.orders?.weeklyStats?.labels || [],
                      series: [stats.orders?.weeklyStats?.orders || []]
                    }}
                    type="Line"
                    options={{
                      low: 0,
                      showArea: false,
                      height: "245px",
                      axisX: {
                        showGrid: false,
                      },
                      lineSmooth: true,
                      showLine: true,
                      showPoint: true,
                      fullWidth: true,
                      chartPadding: {
                        right: 50
                      }
                    }}
                  />
                </div>
              </Card.Body>
              <Card.Footer>
                <hr />
                <div className="stats">
                  <i className="fas fa-history" /> Updated {new Date(data?.lastUpdated).toLocaleString()}
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="4">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Recent Orders</Card.Title>
                <p className="card-category">Latest Transactions</p>
              </Card.Header>
              <Card.Body>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Order #</th>
                      <th>Amount</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {stats.orders?.recentOrders?.map(order => (
                      <tr key={order.orderNumber}>
                        <td>#{order.orderNumber}</td>
                        <td>R {order.amount.toFixed(2)}</td>
                        <td>{order.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer>
                <hr />
                <div className="stats">
                  <i className="fas fa-calendar" /> Last 3 orders
                </div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </ErrorBoundary>
  );
}

export default Dashboard;
