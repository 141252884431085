import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Card, Container, Row, Col } from "react-bootstrap";
import { sendRequest } from "services/GeneralServices";
import DataTable from "components/DataTable";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";

function ApprovedBuyer() {
  const [items, setItems] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchBuyers();
  }, []);

  const fetchBuyers = async () => {
    setIsLoading(true);
    setError("");
    try {
      const listOfBuyers = await sendRequest("buyers", "GET");
      if (listOfBuyers?.status === 200 || listOfBuyers?.status === 201) {
        const activeBuyers = listOfBuyers?.data.filter(
          (buyer) => buyer?.type === "consumer"
        );
        setItems(activeBuyers);
      } else {
        throw new Error(listOfBuyers?.msg || "Failed to fetch buyers");
      }
    } catch (error) {
      setError(`Failed to fetch buyers: ${error.message || 'Unknown error occurred'}`);
    } finally {
      setIsLoading(false);
    }
  };

  const updateState = (item) => {
    setItems(prevItems => {
      const itemIndex = prevItems.findIndex((data) => data._id === item._id);
      if (itemIndex === -1) return prevItems;
      
      return [
        ...prevItems.slice(0, itemIndex),
        item,
        ...prevItems.slice(itemIndex + 1),
      ];
    });
  };

  const deleteItemFromState = (id) => {
    setItems(prevItems => prevItems.filter(item => item._id !== id));
  };

  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <ErrorBoundary>
      <Container fluid>
        {error && (
          <Row>
            <Col xs={12}>
              <ErrorMessage
                title="Error Loading Buyers"
                message={error}
                onClose={() => setError("")}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">User's account Management</Card.Title>
                <p className="card-category">List of all Consumer Buyers</p>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                {!items?.length ? (
                  <Container className="text-center">
                    <p style={{ color: "gray" }}>
                      Your list is currently empty.
                    </p>
                  </Container>
                ) : (
                  <DataTable
                    items={items}
                    updateState={updateState}
                    deleteItemFromState={deleteItemFromState}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </ErrorBoundary>
  );
}

export default ApprovedBuyer;
