import React, { useState } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import { sendRequest } from "services/GeneralServices";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import EnhancedTable from "components/DataTable/EnhancedTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Products() {
  const [data, setData] = useState({ items: [], total: 0 });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    total: 0,
    page: 1,
    pages: 1,
    limit: 10
  });

  const columns = [
    {
      key: 'name',
      label: 'Product Name',
      sortable: true
    },
    {
      key: 'category',
      label: 'Category',
      sortable: true,
      render: (item) => item?.category?.name || '-'
    },
    {
      key: 'price',
      label: 'Price',
      sortable: true,
      render: (item) => `R ${item.price || 0}`
    },
    {
      key: 'quantity',
      label: 'Quantity',
      sortable: true
    },
    {
      key: 'province',
      label: 'Province',
      sortable: true
    },
    {
      key: 'owner',
      label: 'Farmer',
      sortable: true,
      render: (item) => item?.owner?.user?.firstname 
        ? `${item.owner.user.firstname} ${item.owner.user.lastname}`
        : '-'
    },
    {
      key: 'createdAt',
      label: 'Listed Date',
      sortable: true,
      render: (item) => new Date(item.createdAt).toLocaleDateString()
    }
  ];

  const fetchProducts = async (queryParams) => {
    setIsLoading(true);
    setError("");
    try {
      const url = `products?page=${queryParams.page}&limit=${queryParams.limit}&sort=${queryParams.sort}${queryParams.search ? `&search=${queryParams.search}` : ''}`;
      const response = await sendRequest(url, "GET");
      
      if (response?.success) {
        setData({
          items: response.data,
          total: response.pagination.total
        });
        setPagination(response.pagination);
      } else {
        throw new Error(response?.msg || "Failed to fetch products");
      }
    } catch (error) {
      setError(error.message || "An error occurred while fetching products");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        await sendRequest(`products/${id}`, "DELETE");
        fetchProducts({ page: 1, limit: 10, sort: '-createdAt' });
      } catch (error) {
        setError(error.message || "Failed to delete product");
      }
    }
  };

  const renderActions = (item) => (
    <>
      <FontAwesomeIcon
        icon="eye"
        className="action-icon text-info"
        onClick={() => window.open(`/product/${item._id}`, '_blank')}
        title="View Product"
      />
      <FontAwesomeIcon
        icon="trash-can"
        className="action-icon text-danger"
        onClick={() => handleDelete(item._id)}
        title="Delete Product"
      />
    </>
  );

  return (
    <ErrorBoundary>
      <Container fluid>
        {error && (
          <Row>
            <Col xs={12}>
              <ErrorMessage
                title="Error Loading Products"
                message={error}
                onClose={() => setError("")}
              />
            </Col>
          </Row>
        )}
        
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Products Management</Card.Title>
                <p className="card-category">List of all available products</p>
              </Card.Header>
              <Card.Body>
                <EnhancedTable
                  columns={columns}
                  data={data.items}
                  totalItems={data.total}
                  isLoading={isLoading}
                  onFetchData={fetchProducts}
                  actions={renderActions}
                  searchPlaceholder="Search products..."
                  pagination={pagination}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </ErrorBoundary>
  );
}

export default Products;
