import React from 'react';
import { Alert, Card } from 'react-bootstrap';
import './ErrorMessage.css';

const ErrorMessage = ({ 
  title = "Error", 
  message, 
  variant = "danger",
  onClose,
  className = ""
}) => {
  return (
    <Card className={`error-message-container ${className}`}>
      <Alert variant={variant} onClose={onClose} dismissible>
        <Alert.Heading>{title}</Alert.Heading>
        <p>{message}</p>
      </Alert>
    </Card>
  );
};

export default ErrorMessage; 