import React, { useState } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import { sendRequest } from "services/GeneralServices";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import EnhancedTable from "components/DataTable/EnhancedTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Orders() {
  const [data, setData] = useState({ items: [], total: 0 });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    total: 0,
    page: 1,
    pages: 1,
    limit: 10
  });

  const columns = [
    {
      key: 'orderNumber',
      label: 'Order #',
      sortable: true,
      render: (item) => `#${item.orderNumber || item._id?.slice(-6).toUpperCase() || '-'}`
    },
    {
      key: 'products',
      label: 'Products',
      sortable: true,
      render: (item) => {
        const products = item?.products || [];
        return products.map(p => p.product_name).join(', ') || '-';
      }
    },
    {
      key: 'products',
      label: 'Quantity',
      render: (item) => {
        const products = item?.products || [];
        return products.map(p => p.quantity).reduce((a, b) => a + b, 0) || '0';
      }
    },
    {
      key: 'products',
      label: 'Buyer Info',
      render: (item) => {
        const firstProduct = item?.products?.[0];
        return firstProduct ? (
          <div>
            <div>{firstProduct.buyer_Name}</div>
            <small className="text-muted">{firstProduct.buyer_Contact}</small>
          </div>
        ) : '-';
      }
    },
    {
      key: 'amount',
      label: 'Total Amount',
      sortable: true,
      render: (item) => `R ${item.amount?.toFixed(2) || '0.00'}`
    },
    {
      key: 'status',
      label: 'Status',
      sortable: true,
      render: (item) => (
        <span className={`badge bg-${getStatusColor(item.status)}`}>
          {item.status || 'N/A'}
        </span>
      )
    },
    {
      key: 'datePurchased',
      label: 'Order Date',
      sortable: true,
      render: (item) => item.datePurchased 
        ? new Date(item.datePurchased).toLocaleDateString()
        : '-'
    }
  ];

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'pending':
        return 'warning';
      case 'accepted by courier':
        return 'info';
      case 'delivered':
        return 'success';
      case 'rejected':
        return 'danger';
      case 'processing':
        return 'primary';
      default:
        return 'secondary';
    }
  };

  const fetchOrders = async (queryParams) => {
    setIsLoading(true);
    setError("");
    try {
      const url = `orders?page=${queryParams.page}&limit=${queryParams.limit}&sort=${queryParams.sort}${queryParams.search ? `&search=${queryParams.search}` : ''}`;
      const response = await sendRequest(url, "GET");
      
      if (response?.status === 200) {
        setData({
          items: response.data,
          total: response.pagination.total
        });
        setPagination(response.pagination);
      } else {
        throw new Error(response?.msg || "Failed to fetch orders");
      }
    } catch (error) {
      setError(error.message || "An error occurred while fetching orders");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateStatus = async (orderId, newStatus) => {
    try {
      await sendRequest(`orders/${orderId}/status`, "PATCH", { status: newStatus });
      fetchOrders({ page: 1, limit: 10, sort: '-createdAt' });
    } catch (error) {
      setError(error.message || "Failed to update order status");
    }
  };

  const renderActions = (item) => (
    <>
      <FontAwesomeIcon
        icon="eye"
        className="action-icon text-info"
        onClick={() => window.open(`/order/${item._id}`, '_blank')}
        title="View Order Details"
      />
      {item.status.toLowerCase() === 'pending' && (
        <>
          <FontAwesomeIcon
            icon="check-circle"
            className="action-icon text-success"
            onClick={() => handleUpdateStatus(item._id, 'Accepted by Courier')}
            title="Accept Order"
          />
          <FontAwesomeIcon
            icon="times-circle"
            className="action-icon text-danger"
            onClick={() => handleUpdateStatus(item._id, 'Rejected')}
            title="Reject Order"
          />
        </>
      )}
    </>
  );

  return (
    <ErrorBoundary>
      <Container fluid>
        {error && (
          <Row>
            <Col xs={12}>
              <ErrorMessage
                title="Error Loading Orders"
                message={error}
                onClose={() => setError("")}
              />
            </Col>
          </Row>
        )}
        
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Orders Management</Card.Title>
                <p className="card-category">List of all orders in the system</p>
              </Card.Header>
              <Card.Body>
                <EnhancedTable
                  columns={columns}
                  data={data.items}
                  totalItems={data.total}
                  isLoading={isLoading}
                  onFetchData={fetchOrders}
                  actions={renderActions}
                  searchPlaceholder="Search orders..."
                  pagination={pagination}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </ErrorBoundary>
  );
}

export default Orders;
