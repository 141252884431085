import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { css } from "@emotion/react";
import { BeatLoader } from "react-spinners";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import { sendRequest } from "services/GeneralServices";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import "../index.css";

export default function Login() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const override = css`
    margin: 0 auto;
    border-color: red;
    alignself: "center";
  `;

  useEffect(() => {
    // Check if redirected due to token expiration
    const params = new URLSearchParams(window.location.search);
    if (params.get('expired') === 'true') {
      setError('Your session has expired. Please login again.');
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError("");

    try {
      if (!email || !password) {
        throw new Error("Please enter both email and password");
      }

      const authDetails = await sendRequest(
        "auth/login",
        "POST",
        {
          email: email,
          password: password,
        },
        true
      ).catch(err => {
        // Prevent the request error from triggering any redirects
       //  throw new Error(err.response?.data?.message || err.message || "Login failed");
       setError(err.response?.data?.message || err.message || "Login failed");
      });
      
      if (authDetails?.data?.token) {
        localStorage.setItem('token', authDetails.data.token);
        if (authDetails.data.user) {
          localStorage.setItem('user', JSON.stringify(authDetails.data.user));
        }
        
        history.push("/admin/dashboard");
      } else {
        throw new Error("Invalid login credentials");
      }
    } catch (error) {
      setError(error.message || "An error occurred while trying to login");
      alert(error.message || "An error occurred while trying to login");
    } finally {
      setLoading(false);
    }
  };

  return (
    <ErrorBoundary>
      <div className="Auth-form-container">
        <Form className="Auth-form" onSubmit={handleSubmit}>
          <div className="Auth-form-content">
            {error && (
              <ErrorMessage
                title="Login Error"
                message={error}
                onClose={() => setError("")}
              />
            )}
            
            <div className="loader">
              <BeatLoader
                color={"#008037"}
                loading={loading}
                css={override}
                size={10}
              />
            </div>

            <h3 className="Auth-form-title">Sign In</h3>

            <Form.Group className="mt-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                className="mt-1"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={loading}
              />
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                className="mt-1"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={loading}
              />
            </Form.Group>

            <div className="d-grid gap-2 mt-3">
              <Button
                type="submit"
                disabled={loading}
                variant="primary"
              >
                {loading ? 'Signing in...' : 'Sign In'}
              </Button>
            </div>

            <p className="forgot-password text-right mt-2">
              Efama <a href="https://www.efama.africa/">stay logged in</a>
            </p>
          </div>
        </Form>
      </div>
    </ErrorBoundary>
  );
}
