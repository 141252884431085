import React, { useState } from "react";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { sendRequest } from "services/GeneralServices";
import EnhancedTable from "components/DataTable/EnhancedTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import UserFormModal from "components/UserFormModal/UserFormModal";

function Users() {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 10,
    sortBy: 'createdAt',
    sortOrder: -1,
    search: ''
  });
  const [modalMode, setModalMode] = useState('edit'); // 'edit' or 'add'

  // Query for users list
  const { data: usersData, isLoading } = useQuery({
    queryKey: ['users', queryParams],
    queryFn: () => fetchUsers(queryParams),
    keepPreviousData: true
  });

  // Query for single user
  const { data: userData } = useQuery({
    queryKey: ['user', selectedUser?._id],
    queryFn: () => fetchUser(selectedUser?._id),
    enabled: !!selectedUser?._id,
    staleTime: 5 * 60 * 1000 // 5 minutes
  });

  // Mutations
  const updateUserMutation = useMutation({
    mutationFn: (updatedUser) => 
      sendRequest(`users/${updatedUser._id}`, 'PUT', updatedUser),
    onSuccess: (data, variables) => {
      // Update queries
      queryClient.setQueryData(['user', variables._id], data);
      queryClient.invalidateQueries(['users']);
      setIsEditing(false);
    }
  });

  const deleteUserMutation = useMutation({
    mutationFn: (userId) => sendRequest(`users/${userId}`, 'DELETE'),
    onSuccess: () => {
      queryClient.invalidateQueries(['users']);
    }
  });

  // Fetch functions
  const fetchUsers = async (params) => {
    const queryString = new URLSearchParams({
      page: params.page,
      limit: params.limit,
      sortBy: params.sortBy,
      sortOrder: params.sortOrder,
      ...(params.search && { search: params.search })
    }).toString();

    const response = await sendRequest(`users?${queryString}`);
    return response.data;
  };

  const fetchUser = async (userId) => {
    if (!userId) return null;
    const response = await sendRequest(`users/${userId}`);
    return response.data.user;
  };

  const handleFetchData = (newParams) => {
    setQueryParams(prev => ({
      ...prev,
      ...newParams
    }));
  };

  const handleViewUser = (userId) => {
    const user = usersData?.users.find(u => u._id === userId);
    if (user) {
      setSelectedUser(user);
      setShowModal(true);
    }
  };

  const handleDelete = (userId) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      deleteUserMutation.mutate(userId);
    }
  };

  const handleChange = (e) => {
    setSelectedUser(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateUserMutation.mutate(selectedUser);
  };

  const columns = [
    {
      key: 'firstname',
      label: 'First Name',
      sortable: true
    },
    {
      key: 'lastname',
      label: 'Last Name',
      sortable: true
    },
    {
      key: 'username',
      label: 'Username',
      sortable: true
    },
    {
      key: 'email',
      label: 'Email',
      sortable: true
    },
    {
      key: 'role',
      label: 'Role',
      sortable: true
    }
  ];

  const renderActions = (user) => (
    <>
      <Button
        variant="info"
        size="sm"
        onClick={() => handleViewUser(user._id)}
        className="me-2"
        title="View Details"
      >
        <FontAwesomeIcon icon="eye" />
      </Button>
      <Button
        variant="danger"
        size="sm"
        onClick={() => handleDelete(user._id)}
        title="Delete"
      >
        <FontAwesomeIcon icon="trash" />
      </Button>
    </>
  );

  // Add button in the header
  const renderHeader = () => (
    <div className="d-flex justify-content-between align-items-center mb-3">
      <div>
        <Card.Title as="h4">Users Management</Card.Title>
        <p className="card-category">List of all users in the system</p>
      </div>
      <Button 
        variant="primary" 
        onClick={handleAddUser}
      >
        <FontAwesomeIcon icon="circle-plus" className="me-2" />
        Add User
      </Button>
    </div>
  );

  const handleAddUser = () => {
    setModalMode('create');
    setSelectedUser(null);
    setShowModal(true);
  };

  const handleModalSubmit = async (formData) => {
    try {
      if (modalMode === 'create') {
        const response = await sendRequest('auth/register', 'POST', {
          ...formData,
          buyerType: formData.role === "buyer" ? formData.type : null,
        }, false, true);
        return response;
      } else {
        return updateUserMutation.mutateAsync(formData);
      }
    } catch (error) {
      throw error;
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card>
            <Card.Header>
              {renderHeader()}
            </Card.Header>
            <Card.Body>
              <EnhancedTable
                columns={columns}
                data={usersData?.users || []}
                pagination={{
                  total: usersData?.pagination?.total || 0,
                  page: usersData?.pagination?.page || 1,
                  pages: usersData?.pagination?.pages || 1,
                  limit: usersData?.pagination?.limit || 10
                }}
                onFetchData={handleFetchData}
                isLoading={isLoading}
                actions={renderActions}
                searchPlaceholder="Search by name, username, or email..."
                itemsPerPageOptions={[10, 25, 50, 100]}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <UserFormModal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setSelectedUser(null);
          setModalMode('read');
        }}
        user={selectedUser}
        mode={modalMode}
        onSubmit={handleModalSubmit}
      />
    </Container>
  );
}

export default Users; 