import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';
import { BsPerson } from 'react-icons/bs';
import './UserProfile.css';

function UserProfile() {
  const [showProfile, setShowProfile] = useState(false);
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    history.push('/login');
  };

  // Close profile dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.user-profile-container')) {
        setShowProfile(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <div className="user-profile-container">
      <div 
        className="user-icon" 
        onClick={(e) => {
          e.stopPropagation();
          setShowProfile(!showProfile);
        }}
      >
        <BsPerson size={20} />
      </div>
      
      {showProfile && (
        <Card className="user-profile-card">
          <Card.Body className="p-0">
            <div className="user-info">
              <h6>{user.firstname} {user.lastname}</h6>
              <p className="text-muted">{user.email}</p>
              <p className="badge bg-info">{user.role || 'Admin'}</p>
            </div>
            <div className="px-3 pb-3">
              <Button
                variant="danger"
                size="sm"
                className="w-100"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </div>
          </Card.Body>
        </Card>
      )}
    </div>
  );
}

export default UserProfile; 