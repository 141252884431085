import React from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { sendRequest } from "services/GeneralServices";
import Spinner from "react-bootstrap/Spinner";
class AddEditForm extends React.Component {
  state = {
    _id: 0,
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    type: "",
    // username: '',
    isVerified: false,
    businessName: "",
    businessType: "",
    companySize: "",
    position: "",
    preferToBuy: "",
    revenueSize: "",
    smme: "",
    userId:"",
    isLoading:false
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onApproveUser = async(e) => {
    this.setState({ [e.target.name]: e.target.value });
    const res = await sendRequest(`verify-user/${this.state.userId}`,"PATCH",null,true);
    if (res) {
      if (res.status === 200 || res.status === 201) {
        //this.props.deleteItemFromState(id);
      } else {
        window.alert(res?.msg || "Error updating approval status");
      }
    } else {
      window.alert("Something went wrong while approving the user");
    }

  }

  submitFormAdd = (e) => {

    e.preventDefault();
    this.props.addItemToState({
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      email: this.state.email,
      phone: this.state.phone,
      // username:this.state.username,
      isVerified: this.state.isVerified,
      type: this.state.type,
    });
    this.props.toggle();
  };

  submitFormEdit = async (e) => {
    this.setState({isLoading:true});
    e.preventDefault();
    this.props.updateState({
      _id: this.state._id,
      user: {
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        email: this.state.email,
        phone: this.state.phone,
        isVerified: this.state.isVerified,
        _id:this.state.userId,
      },
      type: this.state.type,
      businessName: this.state.businessName,
      businessType: this.state.businessType,
      companySize: this.state.companySize,
      position: this.state.position,
      preferToBuy: this.state.preferToBuy,
      revenueSize: this.state.revenueSize,
      smme: this.state.smme,
    });

    const user = await sendRequest(`/users/${this.state.userId}`,'PATCH',{
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      location: "",
      province: "",
      phone: this.state.phone,
      email: this.state.email
    },true);
    if (user) {
      if (user.status === 200 || user.status === 201) {
        // Success case - no need for console.log
      } else {
        window.alert(user?.msg || "Error updating user information");
      }
    } else {
      window.alert("An error occurred while updating user information");
    }

    const kyc = await sendRequest(`/kyc/buyer/${this.state.userId}`,'PATCH',{
      type: this.state.type,
      businessName: this.state.businessName,
      businessType: this.state.businessType,
      companySize: this.state.companySize,
      revenueSize: this.state.revenueSize,
      position: this.state.position,
      smme: this.state.smme,
      preferToBuy: this.state.preferToBuy,
      address: {
        // line1: this.state.line1,
        // line2: this.state.line2,
        // city: this.state.city,
        // country: this.state.country,
        // zip: this.state.zip,
        // state: this.state.state
      }
    },true);
    if (kyc) {
      if (kyc.status === 200 || kyc.status === 201) {
        // Success case - no need for console.log
      } else {
        window.alert(kyc?.msg || "Error updating KYC information");
      }
    } else {
      window.alert("An error occurred while updating KYC information");
    }
    this.setState({isLoading:false});
    this.props.toggle();
  };

  componentDidMount() {
    // if item exists, populate the state with proper data
    if (this.props.item) {
      const {
        _id,
        user,
        type,
        businessName,
        businessType,
        companySize,
        position,
        preferToBuy,
        revenueSize,
        smme,
      } = this.props.item;

      this.setState({
        _id,
        firstname: user?.firstname,
        lastname: user?.lastname,
        email: user?.email,
        phone: user?.phone,
        userId: user?._id,
        isVerified: user?.isVerified,
        type: type,
        businessName,
        businessType,
        companySize,
        position,
        preferToBuy,
        revenueSize,
        smme,
      });
    }
  }

  render() {
    return (
      <>
            {this.state.isLoading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Submitting...</span>
          </Spinner>
          <p>This might take few seconds</p>
        </div>
      ) :
      (<Form
        onSubmit={this.props.item ? this.submitFormEdit : this.submitFormAdd}
      >
        <FormGroup>
          <Label for="first">First Name</Label>
          <Input
            type="text"
            name="firstname"
            id="firstname"
            onChange={this.onChange}
            value={this.state.firstname === null ? "" : this.state.firstname}
          />
        </FormGroup>
        <FormGroup>
          <Label for="lastname">Last Name</Label>
          <Input
            type="text"
            name="lastname"
            id="lastname"
            onChange={this.onChange}
            value={this.state.lastname === null ? "" : this.state.lastname}
          />
        </FormGroup>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input
            type="email"
            name="email"
            id="email"
            onChange={this.onChange}
            value={this.state.email === null ? "" : this.state.email}
          />
        </FormGroup>
        <FormGroup>
          <Label for="phone">Phone</Label>
          <Input
            type="text"
            name="phone"
            id="phone"
            onChange={this.onChange}
            value={this.state.phone === null ? "" : this.state.phone}
            placeholder="ex. 555-555-5555"
          />
        </FormGroup>
        <FormGroup>
          <Label for="hobby">Selected Buyer Type</Label>
          <Input
            disabled
            type="text"
            name="type"
            id="type"
            onChange={this.onChange}
            value={this.state.type}
          />
        </FormGroup>
        <div>
          <label>
            Change Buyer Type:
            <select
              name="type"
              style={{
                width: "100px",
                padding: 10,
                borderRadius: 10,
                width: "450px",
                margin: "10px 0px",
              }}
              value={this.state.type === null ? "" : this.state.type}
              onChange={this.onChange}
            >
              <option value="">-- Select --</option>
              <option value="Commercial">Commercial</option>
              <option value="Consumer">Consumer</option>
            </select>
          </label>
        </div>
        <h6 class="text-uppercase">Buyer Profile</h6>
        <hr class="solid" />
        <FormGroup>
          <Label for="hobby">Business Name</Label>
          <Input
            type="text"
            name="businessName"
            id="businessName"
            onChange={this.onChange}
            value={this.state.businessName}
          />
        </FormGroup>
        <div>
          <label>
           Business Type
            <select
              name="businessType"
              style={{
                width: "100px",
                padding: 10,
                borderRadius: 10,
                width: "450px",
                margin: "10px 0px",
              }}
              value={
                this.state.businessType
              }
              onChange={this.onChange}
            >
              <option value="">-- Select --</option>
              <option value="Private">Private</option>
              <option value="Sole Proprietor">Sole Proprietor</option>
              <option value="Close Corporation">Close Corporation</option>
              <option value="Trust">Trust</option>
              <option value="Public Company">Public Company</option>
              <option value="NPO">NPO</option>
              <option value="Co-Operatives">Co-Operatives</option>
            </select>
          </label>
        </div>
        <FormGroup>
          <Label for="hobby">Company Size</Label>
          <Input
            type="text"
            name="companySize"
            id="companySize"
            onChange={this.onChange}
            value={this.state.companySize}
          />
        </FormGroup>
        <div>
          <label>
           Select Position
            <select
              name="position"
              style={{
                width: "100px",
                padding: 10,
                borderRadius: 10,
                width: "450px",
                margin: "10px 0px",
              }}
              value={
                this.state.position
              }
              onChange={this.onChange}
            >
              <option value="">-- Select --</option>
              <option value="Buyer">Buyer</option>
              <option value="Office Staff">Office Staff</option>
              <option value="Owner / Director">Owner / Director</option>
            </select>
          </label>
        </div>
        <FormGroup>
          <Label for="hobby">Revenue Size</Label>
          <Input
            type="text"
            name="revenueSize"
            id="revenueSize"
            onChange={this.onChange}
            value={this.state.revenueSize}
          />
        </FormGroup>
        <div>
          <label>
          SMME
            <select
              name="smme"
              style={{
                width: "100px",
                padding: 10,
                borderRadius: 10,
                width: "450px",
                margin: "10px 0px",
              }}
              value={
                this.state.smme
              }
              onChange={this.onChange}
            >
              <option value="">-- Select --</option>
              <option value="Medium to Large">Medium to Large</option>
              <option value="Listed Company">Listed Company</option>
              <option value="State Owned(SOE)">State Owned(SOE)</option>
              <option value="NGO / NPO">NGO / NPO</option>
            </select>
          </label>
        </div>
        <h6 class="text-uppercase">Approval Section</h6>
        <hr class="solid" />
        <FormGroup>
          <Label for="location">Is the Buyer Account Verified ? </Label>
          <Input
            disabled
            type="text"
            name="isVerified"
            id="isVerified"
            onChange={this.onChange}
            value={this.state.isVerified === null ? "" : this.state.isVerified}
          />
        </FormGroup>
        <div>
          <label>
            Change Approval status:
            <select
              name="isVerified"
              style={{
                width: "100px",
                padding: 10,
                borderRadius: 10,
                width: "450px",
                margin: "10px 0px",
              }}
              value={
                this.state.isVerified === null ? "" : this.state.isVerified
              }
              onChange={this.onApproveUser}
            >
              <option value="">-- Select --</option>
              <option value="true">Approved</option>
              <option value="false">Is not Approved</option>
            </select>
          </label>
        </div>
        <Button>Submit</Button>
      </Form>)}
     </>
    );
  }
}

export default AddEditForm;
