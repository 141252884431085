import React from "react";
import { Modal } from "react-bootstrap";
import "./BaseModal.css";

const BaseModal = ({ 
  show, 
  onHide, 
  title,
  children,
  footer,
  size = "lg",
  className = "",
  mode = "read"
}) => {
  return (
    <Modal 
      show={show} 
      onHide={onHide} 
      size={size}
      dialogClassName={`base-modal ${className}`}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={mode === 'read' ? 'read-only-mode' : ''}>
        <div className="modal-content-wrapper">
          {children}
        </div>
      </Modal.Body>
      <Modal.Footer className={mode === 'read' ? 'read-only-footer' : ''}>
        {footer}
      </Modal.Footer>
    </Modal>
  );
};

export default BaseModal; 