import React from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { sendRequest } from "services/GeneralServices";
import { adminSignup } from "services/GeneralServices";
import Spinner from "react-bootstrap/Spinner";
class AddEditForm extends React.Component {
  state = {
    _id: 0,
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    type: "",
    username: "",
    isVerified: true,
    userId:"",
    isLoading:false
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitFormEdit = async(e) => {
    e.preventDefault();
    this.setState({isLoading:true});
    this.props.updateState({
      _id: this.state._id,
      user: {
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        username: this.state.username,
        email: this.state.email,
        phone: this.state.phone,
        isVerified: this.state.isVerified,
        _id:this.state.userId,
      },
      type: this.state.type,
    });
    const user = await sendRequest(`/users/${this.state.userId}`,'PATCH',{
      firstname: this.state.firstname,
      lastname: this.state.lastname,
      location: "",
      province: "",
      phone: this.state.phone,
      email: this.state.email
    },true);
    if (user) {
      if (user.status === 200 || user.status === 201) {
        window.alert("User updated successfully");
      } else {
        window.alert(user?.msg);
      }
    } else {
      window.alert("An error occurred while updating user");
    }
    this.setState({isLoading:false});
    this.props.toggle();
  };

  componentDidMount() {
    // if item exists, populate the state with proper data
    if (this.props.item) {
      const { _id, user, type } = this.props.item;
      this.setState({
        _id,
        firstname: user?.firstname,
        lastname: user?.lastname,
        username: user?.username,
        userId:user?._id,
        email: user?.email,
        phone: user?.phone,
        isVerified: user?.isVerified,
        type: type,
      });
    }
  }

  render() {
    return (
      <> {this.state.isLoading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Submitting...</span>
          </Spinner>
          <p>This might take few seconds</p>
        </div>
      ) :(<Form
        onSubmit={this.props.item ? this.submitFormEdit : this.submitFormAdd}
      >
        <FormGroup>
          <Label for="first">First Name</Label>
          <Input
            type="text"
            name="firstname"
            id="firstname"
            onChange={this.onChange}
            value={this.state.firstname === null ? "" : this.state.firstname}
          />
        </FormGroup>
        <FormGroup>
          <Label for="lastname">Last Name</Label>
          <Input
            type="text"
            name="lastname"
            id="lastname"
            onChange={this.onChange}
            value={this.state.lastname === null ? "" : this.state.lastname}
          />
        </FormGroup>
        <FormGroup>
          <Label for="username">User Name</Label>
          <Input
            type="text"
            name="username"
            id="username"
            onChange={this.onChange}
            value={this.state.username === null ? "" : this.state.username}
          />
        </FormGroup>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input
            type="email"
            name="email"
            id="email"
            onChange={this.onChange}
            value={this.state.email === null ? "" : this.state.email}
          />
        </FormGroup>
        <FormGroup>
          <Label for="phone">Phone</Label>
          <Input
            type="text"
            name="phone"
            id="phone"
            onChange={this.onChange}
            value={this.state.phone === null ? "" : this.state.phone}
            placeholder="ex. 555-555-5555"
          />
        </FormGroup>
        {/* <FormGroup>
          <Label for="location">Is the Buyer Account Verified ? </Label>
          <Input type="text" name="isVerified" id="isVerified" onChange={this.onChange} value={this.state.isVerified === null ? '' : this.state.isVerified}  />
          
        </FormGroup> */}
        <FormGroup>
          <Label for="hobby">Selected Buyer Type</Label>
          <Input
            disabled
            type="text"
            name="type"
            id="type"
            onChange={this.onChange}
            value={this.state.type}
          />
        </FormGroup>
        <div>
          <label>
            Change Buyer Type:
            <select
              name="type"
              style={{
                width: "100px",
                padding: 10,
                borderRadius: 10,
                width: "450px",
                margin: "10px 0px",
              }}
              value={this.state.type === null ? "" : this.state.type}
              onChange={this.onChange}
            >
              <option value="">-- Select --</option>
              <option value="Commercial">Commercial</option>
              <option value="Consumer">Consumer</option>
            </select>
          </label>
        </div>
        <Button>Submit</Button>
      </Form>)}</>
    );
  }
}

export default AddEditForm;
