import React, { useState, useEffect } from 'react';
import {
  Table,
  Form,
  InputGroup,
  Button,
  Pagination,
  Card,
  Row,
  Col,
  Dropdown
} from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './EnhancedTable.css';

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return [debouncedValue];
}

const EnhancedTable = ({
  columns,
  data,
  itemsPerPageOptions = [10, 25, 50],
  onFetchData,
  totalItems = 0,
  isLoading,
  actions,
  searchPlaceholder = "Search...",
  pagination = { total: 0, page: 1, pages: 1, limit: 10 }
}) => {
  const [currentPage, setCurrentPage] = useState(pagination.page);
  const [itemsPerPage, setItemsPerPage] = useState(pagination.limit);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'desc' });
  const [debouncedSearch] = useDebounce(searchTerm, 500);

  useEffect(() => {
    fetchData();
  }, [currentPage, itemsPerPage, sortConfig, debouncedSearch]);

  useEffect(() => {
    setCurrentPage(pagination.page);
    setItemsPerPage(pagination.limit);
  }, [pagination.page, pagination.limit]);

  const fetchData = () => {
    const queryParams = {
      page: currentPage,
      limit: itemsPerPage,
      sort: `${sortConfig.direction === 'desc' ? '-' : ''}${sortConfig.key}`,
      search: debouncedSearch
    };
    onFetchData(queryParams);
  };

  const handleSort = (key) => {
    setSortConfig(prevSort => ({
      key,
      direction: prevSort.key === key && prevSort.direction === 'asc' ? 'desc' : 'asc'
    }));
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(Number(value));
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    if (currentPage < pagination.pages) {
      setCurrentPage(prev => prev + 1);
    }
  };

  const handleLastPage = () => {
    if (currentPage !== pagination.pages) {
      setCurrentPage(pagination.pages);
    }
  };

  const renderPagination = () => {
    const pages = [];
    const maxVisiblePages = 5;
    const totalPages = pagination.pages;
    
    // Calculate the range of visible page numbers
    let startPage, endPage;
    
    if (totalPages <= maxVisiblePages) {
      // If total pages are less than max visible, show all pages
      startPage = 1;
      endPage = totalPages;
    } else {
      // Calculate start and end pages for when we have many pages
      const middlePage = Math.floor(maxVisiblePages / 2);
      
      if (currentPage <= middlePage) {
        // Near the start
        startPage = 1;
        endPage = maxVisiblePages;
      } else if (currentPage + middlePage >= totalPages) {
        // Near the end
        startPage = totalPages - maxVisiblePages + 1;
        endPage = totalPages;
      } else {
        // In the middle
        startPage = currentPage - middlePage;
        endPage = currentPage + middlePage;
      }
    }

    // Always add First and Prev buttons
    pages.push(
      <Pagination.First 
        key="first" 
        disabled={currentPage === 1}
        onClick={() => setCurrentPage(1)}
      />
    );
    pages.push(
      <Pagination.Prev 
        key="prev" 
        disabled={currentPage === 1}
        onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
      />
    );

    // Add ellipsis at start if needed
    if (startPage > 1) {
      pages.push(<Pagination.Ellipsis key="ellipsis-start" />);
    }

    // Add page numbers
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    // Add ellipsis at end if needed
    if (endPage < totalPages) {
      pages.push(<Pagination.Ellipsis key="ellipsis-end" />);
    }

    // Always add Next and Last buttons
    pages.push(
      <Pagination.Next 
        key="next" 
        disabled={currentPage >= totalPages}
        onClick={handleNextPage}
      />
    );
    pages.push(
      <Pagination.Last 
        key="last" 
        disabled={currentPage >= totalPages}
        onClick={handleLastPage}
      />
    );

    return pages;
  };

  const renderSortIcon = (columnKey) => {
    if (sortConfig.key === columnKey) {
      return (
        <FontAwesomeIcon
          icon={sortConfig.direction === 'asc' ? 'sort-up' : 'sort-down'}
          className="ms-1"
        />
      );
    }
    return <FontAwesomeIcon icon="sort" className="ms-1 text-muted" />;
  };

  const paginationText = `Showing ${Math.min((pagination.page - 1) * pagination.limit + 1, pagination.total)} to ${Math.min(pagination.page * pagination.limit, pagination.total)} of ${pagination.total} entries`;

  return (
    <div className="enhanced-table">
      <Row className="mb-3">
        <Col md={6}>
          <InputGroup>
            <Form.Control
              placeholder={searchPlaceholder}
              value={searchTerm}
              onChange={handleSearch}
            />
            <Button variant="outline-secondary">
              <FontAwesomeIcon icon="search" />
            </Button>
          </InputGroup>
        </Col>
        <Col md={6} className="text-end">
          <Form.Select
            className="d-inline-block w-auto"
            value={itemsPerPage}
            onChange={(e) => handleItemsPerPageChange(e.target.value)}
          >
            {itemsPerPageOptions.map(option => (
              <option key={option} value={option}>
                Show {option} entries
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>

      <div className="table-responsive">
        <Table hover>
          <thead>
            <tr>
              {columns.map(column => (
                <th
                  key={column.key}
                  onClick={() => column.sortable && handleSort(column.key)}
                  className={column.sortable ? 'sortable' : ''}
                >
                  {column.label}
                  {column.sortable && renderSortIcon(column.key)}
                </th>
              ))}
              {actions && <th style={{ width: '120px' }}>Actions</th>}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={columns.length + (actions ? 1 : 0)} className="text-center">
                  Loading...
                </td>
              </tr>
            ) : data.length === 0 ? (
              <tr>
                <td colSpan={columns.length + (actions ? 1 : 0)} className="text-center">
                  No data available
                </td>
              </tr>
            ) : (
              data.map((item, index) => (
                <tr key={item._id || index}>
                  {columns.map(column => (
                    <td key={column.key}>
                      {column.render ? column.render(item) : item[column.key]}
                    </td>
                  ))}
                  {actions && (
                    <td style={{ width: '120px' }}>
                      <div className="action-icons">
                        {actions(item)}
                      </div>
                    </td>
                  )}
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>

      <Row className="mt-3">
        <Col md={6}>
          <p>{paginationText}</p>
        </Col>
        <Col md={6}>
          <Pagination className="justify-content-end">
            {renderPagination()}
          </Pagination>
        </Col>
      </Row>
    </div>
  );
};

export default EnhancedTable; 