import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col, Card, Image, Spinner } from "react-bootstrap";
import defaultAvatar from "assets/img/Profile_avatar_placeholder_large.png";
import CustomAlert from "components/CustomAlert/CustomAlert";
import BaseModal from "components/Modal/BaseModal";
import "./UserFormModal.css";
import { useQueryClient } from '@tanstack/react-query';

const UserFormModal = ({ 
  show, 
  onHide, 
  user, 
  mode, // 'create', 'edit', or 'read'
  onSubmit 
}) => {
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    phone: "",
    role: "",
    password: "",
    confirmpassword: "",
    type: "",
    province: "",
    location: "",
    isVerified: false,
    isApproved: false,
    profileImage: ""
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isEditing, setIsEditing] = useState(mode === 'create');
  const [validationErrors, setValidationErrors] = useState({});
  const [currentMode, setCurrentMode] = useState(mode);

  useEffect(() => {
    setCurrentMode(mode);
    setIsEditing(mode === 'create');
  }, [mode]);

  useEffect(() => {
    if (show) {
      if (mode === 'create') {
        // Reset form for new user
        setFormData({
          firstname: "",
          lastname: "",
          username: "",
          email: "",
          phone: "",
          role: "",
          password: "",
          confirmpassword: "",
          type: "",
          province: "",
          location: "",
          isVerified: false,
          isApproved: false,
          profileImage: ""
        });
      } else if (user) {
        // Set form data for existing user
        setFormData(user);
      }
    }
  }, [show, user, mode]);

  const roleOptions = [
    { value: "admin", label: "Administrator" },
    { value: "farmer", label: "Farmer" },
    { value: "buyer", label: "Buyer" },
    { value: "logistics", label: "Logistics" }
  ];

  const verificationOptions = [
    { value: true, label: "Verified" },
    { value: false, label: "Not Verified" }
  ];

  const approvalOptions = [
    { value: true, label: "Approved" },
    { value: false, label: "Not Approved" }
  ];

  const provinceOptions = [
    "Eastern Cape",
    "Free State",
    "Gauteng",
    "KwaZulu-Natal",
    "Limpopo",
    "Mpumalanga",
    "North West",
    "Northern Cape",
    "Western Cape"
  ];

  const validateField = (name, value) => {
    // Skip all validation in read mode
    if (currentMode === 'read') return true;
    
    // Only validate in create mode
    if (currentMode === 'edit') return true;

    // Validation only happens in create mode
    switch (name) {
      case 'email':
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? true : 'Invalid email format';
      case 'phone':
        return value ? /^\+?[\d\s-]{10,}$/.test(value) ? true : 'Invalid phone format' : true;
      case 'password':
        return value.length >= 6 ? true : 'Password must be at least 6 characters';
      case 'confirmpassword':
        return value === formData.password ? true : 'Passwords do not match';
      default:
        return true;
    }
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    const newValue = type === 'checkbox' ? e.target.checked : 
                    type === 'select-one' ? 
                      (name === 'isVerified' || name === 'isApproved' ? 
                        value === 'true' : value) : 
                    value;

    setFormData(prev => ({ ...prev, [name]: newValue }));
    
    const validation = validateField(name, newValue);
    if (validation !== true) {
      setValidationErrors(prev => ({ ...prev, [name]: validation }));
    } else {
      setValidationErrors(prev => {
        const { [name]: removed, ...rest } = prev;
        return rest;
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Only validate fields in create mode
    if (mode === 'create') {
      const errors = {};
      Object.keys(formData).forEach(key => {
        const validation = validateField(key, formData[key]);
        if (validation !== true) {
          errors[key] = validation;
        }
      });

      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
        return;
      }
    }

    setIsLoading(true);
    try {
      const response = await onSubmit(formData);
      
      // Check if the response indicates a validation error
      if (response.msg === "Validation failed" && response.errors) {
        setIsError(true);
        const serverErrors = {};
        response.errors.forEach(error => {
          if (serverErrors[error.field]) {
            serverErrors[error.field] += `, ${error.message}`;
          } else {
            serverErrors[error.field] = error.message;
          }
        });
        setValidationErrors(serverErrors);
        setAlertMessage("Please correct the validation errors");
        setShowAlert(true);
        return;
      }
      
      // Check for other types of errors
      if (!response.success) {
        setIsError(true);
        setAlertMessage(response.error || response.msg || "Registration failed");
        setShowAlert(true);
        return;
      }

      // Success case
      setShowAlert(true);
      setIsError(false);
      setAlertMessage(mode === 'create' ? "User registered successfully" : "User updated successfully");
      
      // On success, wait for the alert to be shown before closing
      setTimeout(() => {
        onHide();
        setShowAlert(false);
        // Refresh the users list
        if (mode === 'create') {
          queryClient.invalidateQueries(['users']);
        }
      }, 1500);
    } catch (error) {
      setIsError(true);
      
      // Handle different types of errors
      if (error.errors) {
        // Validation errors
        const serverErrors = {};
        error.errors.forEach(err => {
          if (serverErrors[err.field]) {
            serverErrors[err.field] += `, ${err.message}`;
          } else {
            serverErrors[err.field] = err.message;
          }
        });
        setValidationErrors(serverErrors);
        setAlertMessage("Please correct the validation errors");
      } else if (error.message?.includes("exists")) {
        setAlertMessage(error.message);
      } else if (error.response?.data) {
        setAlertMessage(error.response.data.message || error.response.data.error || "An error occurred");
      } else {
        setAlertMessage(error.message || "An error occurred while processing your request");
      }
      
      setShowAlert(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditClick = () => {
    setCurrentMode('edit');
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setCurrentMode('read');
    setIsEditing(false);
    // Reset form data to original user data
    if (user) {
      setFormData(user);
    }
    setValidationErrors({});
  };

  const renderFooter = () => {
    if (mode === 'read') {
      return (
        <Button 
          variant="info" 
          onClick={handleEditClick}
          className="edit-button"
        >
          Edit
        </Button>
      );
    }

    return (
      <div className="button-group">
        <Button 
          variant="secondary" 
          onClick={mode === 'edit' ? handleCancelEdit : onHide}
        >
          {mode === 'edit' ? 'Cancel' : 'Close'}
        </Button>
        <Button 
          variant="primary" 
          onClick={handleSubmit}
          disabled={isLoading || Object.keys(validationErrors).length > 0}
        >
          {isLoading ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="me-2"
              />
              Loading...
            </>
          ) : (
            mode === 'create' ? 'Register' : 'Save Changes'
          )}
        </Button>
      </div>
    );
  };

  return (
    <BaseModal
      show={show}
      onHide={onHide}
      title={
        mode === 'create' 
          ? 'Add New User' 
          : mode === 'edit' 
            ? 'Edit User' 
            : 'User Details'
      }
      footer={renderFooter()}
      mode={mode}
    >
      {showAlert && (
        <CustomAlert
          variant={isError ? "danger" : "success"}
          heading={mode === 'create' ? "Registration Status" : "Update Status"}
          message={alertMessage}
          onClose={() => setShowAlert(false)}
        />
      )}
      <Form onSubmit={handleSubmit}>
        {mode !== 'create' && (
          <Row className="mb-4">
            <Col md="3" className="text-center">
              <div className="user-profile-image">
                <Image 
                  src={formData.profileImage || defaultAvatar} 
                  alt="User Profile"
                  roundedCircle
                  style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                />
                <div className="mt-2">
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      // Handle image upload
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col md="9">
              <Card className="status-card">
                <Card.Body>
                  <h5 className="form-section-header">Account Status</h5>
                  <Row className="g-3">
                    <Col md="4">
                      <Form.Group>
                        <Form.Label className="required-field">Role</Form.Label>
                        <Form.Select
                          name="role"
                          value={formData.role}
                          onChange={handleChange}
                          disabled={!isEditing}
                        >
                          <option value="">Select Role</option>
                          {roleOptions.map(option => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <Form.Label className="required-field">Verification Status</Form.Label>
                        <Form.Select
                          name="isVerified"
                          value={formData.isVerified?.toString()}
                          onChange={handleChange}
                          disabled={!isEditing}
                        >
                          {verificationOptions.map(option => (
                            <option key={option.value} value={option.value.toString()}>
                              {option.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group>
                        <Form.Label className="required-field">Approval Status</Form.Label>
                        <Form.Select
                          name="isApproved"
                          value={formData.isApproved?.toString()}
                          onChange={handleChange}
                          disabled={!isEditing}
                        >
                          {approvalOptions.map(option => (
                            <option key={option.value} value={option.value.toString()}>
                              {option.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}

        <h5 className="form-section-header">Personal Information</h5>
        <Row>
          <Col md="6">
            <Form.Group className="mb-3">
              <Form.Label className="required-field">First Name</Form.Label>
              <Form.Control
                name="firstname"
                value={formData.firstname}
                onChange={handleChange}
                placeholder="e.g John"
                type="text"
                readOnly={currentMode === 'read'}
                required={currentMode === 'create'}
                isInvalid={!!validationErrors.firstname}
              />
              {validationErrors.firstname && (
                <Form.Control.Feedback type="invalid">
                  {validationErrors.firstname}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group className="mb-3">
              <Form.Label className="required-field">Last Name</Form.Label>
              <Form.Control
                name="lastname"
                value={formData.lastname}
                onChange={handleChange}
                placeholder="e.g Doe"
                type="text"
                readOnly={currentMode === 'read'}
                required={currentMode === 'create'}
                isInvalid={!!validationErrors.lastname}
              />
              {validationErrors.lastname && (
                <Form.Control.Feedback type="invalid">
                  {validationErrors.lastname}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>

        <h5 className="form-section-header">Account Information</h5>
        <Row>
          <Col md="6">
            <Form.Group className="mb-3">
              <Form.Label className="required-field">Username</Form.Label>
              <Form.Control
                name="username"
                value={formData.username}
                onChange={handleChange}
                placeholder="e.g johndoe"
                type="text"
                readOnly={currentMode === 'read'}
                required={currentMode === 'create'}
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group className="mb-3">
              <Form.Label className="required-field">Email</Form.Label>
              <Form.Control
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="e.g john@example.com"
                type="email"
                readOnly={currentMode === 'read'}
                required={currentMode === 'create'}
                isInvalid={!!validationErrors.email}
              />
              {validationErrors.email && (
                <Form.Control.Feedback type="invalid">
                  {validationErrors.email}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md="6">
            <Form.Group className="mb-3">
              <Form.Label className="required-field">Phone</Form.Label>
              <Form.Control
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="e.g +27868403794"
                type="text"
                readOnly={currentMode === 'read'}
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group className="mb-3">
              <Form.Label className="required-field">Role</Form.Label>
              <Form.Select
                name="role"
                value={formData.role}
                onChange={handleChange}
                disabled={currentMode === 'read'}
                required={currentMode === 'create'}
              >
                <option value="">Select Role</option>
                {roleOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        {formData.role === "buyer" && (
          <Row>
            <Col md="6">
              <Form.Group className="mb-3">
                <Form.Label className="required-field">Buyer Type</Form.Label>
                <Form.Select
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                  disabled={currentMode === 'read'}
                  required={currentMode === 'create'}
                >
                  <option value="">Select Type</option>
                  <option value="commercial">Commercial</option>
                  <option value="consumer">Consumer</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        )}

        {mode === 'create' && (
          <Row>
            <Col md="6">
              <Form.Group className="mb-3">
                <Form.Label className="required-field">Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="mb-3">
                <Form.Label className="required-field">Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  name="confirmpassword"
                  value={formData.confirmpassword}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
        )}

        <Row>
          <Col md="6">
            <Form.Group className="mb-3">
              <Form.Label className="required-field">Province</Form.Label>
              <Form.Select
                name="province"
                value={formData.province}
                onChange={handleChange}
                disabled={currentMode === 'read'}
              >
                <option value="">Select Province</option>
                {provinceOptions.map(province => (
                  <option key={province} value={province}>
                    {province}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group className="mb-3">
              <Form.Label className="required-field">Location</Form.Label>
              <Form.Control
                name="location"
                value={formData.location}
                onChange={handleChange}
                placeholder="e.g Pretoria"
                type="text"
                readOnly={currentMode === 'read'}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </BaseModal>
  );
};

export default UserFormModal; 