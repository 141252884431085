import React from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { updateOrderStatus } from "services/GeneralServices";
import Image from "react-bootstrap/Image";
import { updateProductDetails } from "services/GeneralServices";
import { sendRequest } from "services/GeneralServices";
import Spinner from "react-bootstrap/Spinner";
import {storage} from '../../FirebaseConfig';
import {getDownloadURL, ref, uploadBytes} from 'firebase/storage'
import {v4} from "uuid"

class AddProduct extends React.Component {
  state = {
    _id: 0,
    name: "",
    category: "",
    description: "",
    photoDownloadURL: "",
    price: "",
    province: "",
    quantity: "",
    name: "",
    selectedFile: null,
    isLoading:false
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount() {
    // if item exists, populate the state with proper data
    if (this.props.item) {
      const { user } = this.props.item;
      this.setState({ _id : user?._id });
    }
  }

  handleUpload = async (e) => {
    e.preventDefault();
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = (event) => {
      const file = event.target.files[0];
      this.setState({ selectedFile: file });
    };
    input.click();
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    
    try {
      if(this.state.selectedFile == null){
        window.alert("Add Product image");
        return;
      }
      const imageRef = await ref(storage,`products/${v4()}`);
      await uploadBytes(imageRef,this.state.selectedFile).catch((error) => {
        throw new Error('Failed to upload image: ' + error.message);
      });
      
      const imageUrl = await getDownloadURL(imageRef);
      
      const updateProductRes = await sendRequest(
        "products",
        "POST",
        {
          quantity: this.state.quantity,
          name: this.state.name,
          owner: this.state._id,
          category: this.state.category,
          price: this.state.price,
          description: this.state.description,
          image:null,
          province: this.state.province,
          photos:imageUrl
        },
      );

      if (updateProductRes) {
        if (updateProductRes.status === "201" || updateProductRes.status === "200") {
          window.alert("Product added successfully!");
        } else {
          window.alert(`Failed to add product: ${updateProductRes?.msg || 'Unknown error'}`);
        }
      } else {
        window.alert("Failed to add product: No response from server");
      }
    } catch (error) {
      window.alert('Error: ' + error.message);
      this.setState({ isLoading: false });
      return;
    }
    this.setState({ isLoading: false });
    this.props.toggle();
  };

  render() {
    const { selectedFile } = this.state;
    return (
      <>
       {this.state.isLoading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Submitting...</span>
          </Spinner>
          <p>This might take few seconds</p>
        </div>
      ) :
      (<Form>
        <FormGroup>
          <Label for="name">Product Name</Label>
          <Input
            type="text"
            name="name"
            id="name"
            onChange={this.onChange}
            value={this.state.name === null ? "" : this.state.name}
          />
        </FormGroup>
        <div>
          <label>
            Category
            <select
              name="category"
              style={{
                width: "100px",
                padding: 10,
                borderRadius: 10,
                width: "450px",
                margin: "10px 0px",
              }}
              value={this.state.category === null ? "" : this.state.category}
              onChange={this.onChange}
            >
              <option value="">-- Select --</option>
              <option value="645caa5e3ac098e6d142d9b1">Fruits</option>
              <option value="645caa6b3ac098e6d142d9b3">Vegetables</option>
              <option value="645caa743ac098e6d142d9b5">Livestock</option>
              <option value="645caa7f3ac098e6d142d9b7">Meat</option>
            </select>
          </label>
        </div>
        <FormGroup>
          <Label for="date">Product Description</Label>
          <Input
            type="textarea"
            name="description"
            id="description"
            onChange={this.onChange}
            value={
              this.state.description === null ? "" : this.state.description
            }
          />
        </FormGroup>
        <FormGroup>
          <Label for="amount">Price</Label>
          <Input
            type="text"
            name="price"
            id="price"
            onChange={this.onChange}
            value={this.state.price === null ? "" : this.state.price}
          />
        </FormGroup>
        <div>
          <label>
            Select Province
            <select
              name="province"
              style={{
                width: "100px",
                padding: 10,
                borderRadius: 10,
                width: "450px",
                margin: "10px 0px",
              }}
              value={this.state.province}
              onChange={this.onChange}
            >
              <option value="">-- Select --</option>
              <option value='Eastern Cape'>Eastern Cape</option>
              <option value='Free State'>Free State</option>
              <option value='Gauteng'>Gauteng</option>
              <option value='KwaZulu-Natal'>KwaZulu-Natal</option>
              <option value='Limpopo'>Limpopo</option>
              <option value='Mpumalanga'>Mpumalanga</option>
              <option value='North West'>North West</option>
              <option value='Northern Cape'>Northern Cape</option>
              <option value='Western Cape'>Western Cape</option>
            </select>
          </label>
        </div>
        <FormGroup>
          <Label for="amount">Quantity</Label>
          <Input
            type="text"
            name="quantity"
            id="quantity"
            onChange={this.onChange}
            value={this.state.quantity === null ? "" : this.state.quantity}
          />
        </FormGroup>
        <div className="m-3">
          <label className="mx-3">Choose file: </label>
          <input className="d-none" type="file" />
          <button
            className="btn btn-outline-primary"
            onClick={this.handleUpload}
          >
            Upload
          </button>
          {selectedFile && (
            <div>
              <h5>Uploaded Image:</h5>
              <img
                width={100}
                src={URL.createObjectURL(selectedFile)}
                alt="Uploaded"
              />
            </div>
          )}
        </div>
        <Button onClick={this.handleSubmit}>Submit</Button>
      </Form>)}
      </>
    );
  }
}

export default AddProduct;
